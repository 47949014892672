<template>
  <div
    @click="cta"
    class="flip-card"
    :ref="this.id"
    :class="[{ visible: visible }]"
  >
    <div class="flip_content">
      <slot></slot>
      <!-- Slot template -->
      <!-- <div class="front"></div> -->
      <!-- <div class="back"></div>  -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Tep-Flip-Card",
  props: {
    options: {
      type: Object,
      required: false,
      default() {
        return {
          visibleFrom: 100,
          visibleTill: 500,
        };
      },
    },
  },
  data() {
    return {
      id: "",
      offset: null,
    };
  },
  computed: {
    visible() {
      return this.offset
        ? this.offset < this.options.visibleTill &&
            this.offset > this.options.visibleFrom
        : false;
    },
  },
  methods: {
    scrollListener() {
      const card = this.$refs[this.id];
      this.offset = card.getBoundingClientRect().top;
    },
    cta() {
      this.$emit("cta");
    },
  },
  created() {
    // Assign random ref
    const random = Math.floor(Math.random() * 10000);
    this.id = `flip_card_${random}`;

    window.addEventListener("scroll", this.scrollListener);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollListener);
  },
};
</script>

<style scoped lang="scss">
.flip-card {
  perspective: 500px;
  cursor: pointer;
  .flip_content {
    position: relative;
    transition: transform 1s;
    transform-style: preserve-3d;
    width: 100%;
    height: 100%;
    .front,
    .back {
      height: 100%;
      width: 100%;
      position: absolute;
      backface-visibility: hidden;
    }
    .back {
      transform: rotateY(180deg);
    }
  }
  &:hover .flip_content {
    transform: rotateY(180deg);
    transition: transform 0.5s;
  }
}

@media all and (max-width: 700px) {
  .flip-card {
    &.visible .flip_content {
      transform: rotateY(180deg);
      transition: transform 0.5s;
    }
  }
}
</style>
