<template>
  <div class="content operations">
    <banner title="Operations" :image="2"> </banner>

    <div class="section">
      <div class="content_wrapper">
        <h3>Operations</h3>
        <p>
          Taproot is a leading midstream platform in Colorado's DJ Basin that
          has accomplished significant growth since start-up in May 2018,
          successfully securing 325,000 dedicated acres to its crude gathering
          and transportation, produced water gathering and fresh water sourcing
          and delivery businesses.
        </p>
      </div>
    </div>

    <div class="section">
      <div class="content_wrapper centered">
        <flip-card @cta="navigate('crude')" :options="options">
          <div class="front">
            <img src="../assets/icons/crude.svg" alt="Crude Oil" />
            Crude Oil
          </div>
          <div class="back">
            Taproot operates over 100 miles of crude gathering and
            transportation pipeline to support over 325,000 dedicated acres...
            <div class="more">Learn more</div>
          </div>
        </flip-card>

        <flip-card @cta="navigate('produced')" :options="options">
          <div class="front">
            <img src="../assets/icons/produced.svg" alt="Produced Water" />
            Produced Water
          </div>
          <div class="back">
            Taproot operates over 60 miles of produced water gathering pipeline
            with connections to three saltwater disposal...
            <div class="more">Learn more</div>
          </div>
        </flip-card>

        <flip-card @cta="navigate('fresh')" :options="options">
          <div class="front">
            <img src="../assets/icons/fresh.svg" alt="Fresh Water" />
            Fresh Water
          </div>
          <div class="back">
            Taproot's fresh water system consists of 19 fresh water source wells
            supplying over 50,000 barrels per day of source...
            <div class="more">Learn more</div>
          </div>
        </flip-card>
      </div>
    </div>

    <div class="section">
      <div class="content_wrapper">
        <h3>System Map</h3>
        <img
          class="image"
          src="../assets/images/map_all_pipelines_medium.png"
          alt="System Map"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner.vue";
import FlipCard from "@/components/FlipCard.vue";

export default {
  name: "Operation-view",
  components: {
    Banner,
    FlipCard,
  },
  data() {
    return {
      options: {
        visibleFrom: 120,
        visibleTill: 400,
      },
    };
  },
  methods: {
    navigate(to) {
      this.$router.push({ path: `/operations/${to}` });
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss">
.flip-card {
  font-family: "Nunito Sans";
  text-align: center;
  width: 250px;
  height: 200px;
  margin: 20px;
  display: inline-block;
  .flip_content {
    color: var(--font_color_accent);
    border: 1px solid var(--font_color_accent);
    .front {
      font-weight: 700;
      color: var(--font_color_accent);
      background-color: #f9f9f9;
      text-align: center;
      img {
        height: 42px;
        display: block;
        margin: 65px auto 12px auto;
      }
    }
    .back {
      font-family: "PT Serif";
      padding: 15px;
      color: white;
      background: var(--font_color_accent);
      text-align: left;
      line-height: 24px;
    }
  }
  .more {
    padding-top: 10px;
    font-weight: 700;
    font-size: 12px;
  }
}

@media all and (max-width: 700px) {
  .flip-card {
    width: 100%;
    max-width: 350px;
    display: block;
    margin: 100px auto 150px auto;
  }
}
</style>
